export default  {
  'pathOptions': {
    'color': 0x3AB5FE,
    'arrowColor': 0xFFFFFF,
    'raduis': 2.2,
    'animateDrawing': 300,
    'arrowSpeed': 200,
    'arrowSpacing': 10
  }


}
