import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('environment')
            this.debugFolder.close();
        }

        this.setSunLight()
        this.setEnvironmentMap()
    }

    setSunLight()
    {
        /*const ambientLight = new THREE.AmbientLight(0xa28686, 0.002)
        this.scene.add(ambientLight)*/
        /*
                this.sunLight = new THREE.DirectionalLight('#ffffff', 0.5)
                this.sunLight.castShadow = false
                this.sunLight.shadow.camera.far = 165
                this.sunLight.shadow.camera.near = 100
                this.sunLight.shadow.camera.fov = 1
                this.sunLight.shadow.camera.left = - 7
                this.sunLight.shadow.camera.top = 7
                this.sunLight.shadow.camera.right = 7
                this.sunLight.shadow.camera.bottom = - 7
                this.sunLight.shadow.mapSize.set(1024, 1024)
                this.sunLight.position.set(400, 5200, 50)
                this.scene.add(this.sunLight)*/

        this.sunLight = new THREE.DirectionalLight('#ffffff', 0.3)
        //this.sunLight.position.set(-175, 100, -190)
        this.sunLight.position.set(918, 1594, 190)
        this.scene.add(this.sunLight)

        const light = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.7 );
        light.position.set(918, 1594, 190)
        this.scene.add( light );

        //https://www.behance.net/gallery/162981469/AR-Navigator-app


        // Debug
        if(this.debug.active)
        {

        }
    }

    setEnvironmentMap()
    {
        this.environmentMap = {}
        //this.environmentMap.intensity = 1.4
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if(this.debug.active)
        {

        }
    }
}
