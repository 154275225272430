import * as THREE from 'three'
import Experience from '../Experience.js'
import _ from 'lodash';
import { Vector3 } from 'three';


export default class MapModel {
  constructor(){
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources.items.mapModel
    this.debug = this.experience.debug

    this.polygons = new Map()

    this.setModel()
    this.setPolygons()


    this.loadVenue()


    this.clickedObj = null
    //this.experience.emit.on('click-obj', this.onClickPolygon)


    console.log('Render Info::', this.experience.renderer.instance.info)
  }

  setModel(){
    const self = this
    this.model = this.resource.scene
    //this.model.scale.set(0.4, .8, 0.4)

    const pathParams = {
      showModal: true,
    }

    this.debugPathFolder = this.debug.ui.addFolder('MapModel')
    this.debugPathFolder.add( pathParams,'showModal', 'boolean')
        .onChange( (val) => {
          if(val === true)
            this.scene.add(this.model)
          else
            this.scene.remove(this.model)
        });

    this.scene.add(this.model)

    /*const coffeeModel = this.experience.resources.items.coffeeModel.scene
    coffeeModel.scale.set(0.5, 1, 0.5)
    coffeeModel.position.set(-2, -0.390, - 0.9)
    this.scene.add(coffeeModel)*/
  }

  setPolygons(){
    const polygons = new Map();
    const points = new Map();
    this.resource.scene['children'].forEach((node, i) => {
      if (node instanceof THREE.Object3D) {
        node.children.forEach((nodeIn, i) => {
          if (_.includes(nodeIn.name, 'polygon')) {
            polygons.set(nodeIn.name, nodeIn);
          }
        });

        if (_.includes(node.name, 'polygon')) {
          polygons.set(node.name, node);
        }

        if (_.includes(node.name, 'point')) {
          node.visible = false
          points.set(node.name, node);
        }
      }
    })
    this.polygons = polygons
    this.points = points
  }

  loadVenue = () => {
    return this.experience.store.apiStore.getVenue().then(res => {
      this.experience.emit.trigger('onLoadVenue', res)
    })
  }

  onClickPolygon = (intersects) => {
    //console.log ('-->event', intersects );

    if (_.includes(intersects[0].object.name, 'polygon')) {
      const polygon = intersects[0].object

      if (this.clickedObj) {
        this.clickedObj.material = this.clickedObj['userData']['originMatrial'];
        this.clickedObj.userData['is_clicked'] = false;
        this.experience.emit.trigger('polygon-deselect', [this.clickedObj])
      }

      if (polygon.userData['is_clicked'] === true) {
        this.experience.emit.trigger('polygon-select2', [polygon])
        return;
      }

      if (!polygon['userData']['originMatrial']) {
        polygon['userData']['originMatrial'] = polygon.material
      }

      polygon.userData['is_clicked'] = true;
      polygon.material = new THREE.MeshBasicMaterial({ color: new THREE.Color(0x062339) });

      this.experience.emit.trigger('polygon-select', [polygon])

    }

    this.clickedObj = intersects[0].object
  }

  update(){
    //this.collisionEngine.update()
  }
}
