import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { MapControls } from './Controls/MapControls'
//import { OrbitControls } from './Controls/OrbitControls'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        this.setControls()


    }

    setInstance()
    {
        //16 - 25
        this.instance = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height,1, 10000)
        //this.instance = new THREE.OrthographicCamera( this.sizes.width / - 2, this.sizes.width / 2, this.sizes.height / 2, this.sizes.height / - 2,1, 1000 );
        this.instance.position.set(420, 1500, 140)
        //this.instance.lookAt('kamal')
        //this.instance.zoom = 100;
        this.scene.add(this.instance)

        //this.experience.path.drawLine(130, 200, -100)
    }

    setControls()
    {
        //this.controls = new OrbitControls(this.instance, this.canvas)

        this.controls = new MapControls(this.instance, this.canvas)
         //this.controls.maxPolarAngle = 1.35
         //this.controls.maxDistance = 920
         //this.controls.minDistance = 60 //5
         //this.controls.zoomSpeed = .5
         this.controls.enableDamping = false
         //this.controls.dampingFactor = 0.1;

        this.controls.addEventListener( 'start', ()=>{
            this.experience.emit.trigger('camera-move-start')
        } );

        let zoom = null;
        this.controls.addEventListener( 'change', (e,v)=>{
            this.experience.emit.trigger('camera-move-ing', zoom)
        });

        this.controls.addEventListener( 'end', (e)=>{
            this.experience.emit.trigger('camera-move-end')
        });

    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.controls.update()
    }
}
