import * as dat from 'lil-gui'

import Stats from 'stats.js'



export default class Debug
{
    constructor()
    {
        this.active = window.location.hash === '#debug'

        console.log ('-->debug', this.active );

        if(this.active)
        {
            this.ui = new dat.GUI()
        }
    }
}
