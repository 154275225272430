import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';

import testPath from '../../const/testPath.json'

import Experience from '../Experience.js'
import _ from 'lodash'
import * as YUKA from 'yuka/build/yuka.js';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';
import { v4 as uuidv4 } from 'uuid';

import pathBuilder from './PathBuilder'
import PathHelper from './PathHelper'
import PathBuilder from './PathBuilder'

export default class Path extends PathBuilder {
  constructor(){
    super();
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.camera = this.experience.camera.instance
    this.debug = this.experience.debug

    this.PathHelper = this.experience.PathHelper
    this.pathBuilder = this.experience.pathBuilder

    this.onSelectPolygon = this.onSelectPolygon.bind(this);
    this.onSelect2Polygon = this.onSelect2Polygon.bind(this);
    this.onDESelectPolygon = this.onDESelectPolygon.bind(this);

    this.graph = new Map;
    this.loadGraph()

    this.run();

    if (this.debug.active) {
      this.runDebug()
    }

    this.pathFrom = null;
    this.pathTo = null;
  }

  loadGraph = () => {
    const newRes = new Map();
    return this.experience.store.apiStore.getGraph().then(res => {
      this.graphJson = res
      for (let i = 0; i < res.length; i++) {
        newRes.set(res[i]['id'], res[i])
      }
      this.graph = newRes
      this.experience.emit.trigger('onGraphLoaded', [newRes])
    })
  }

  onSelectPolygon = (polygon) => {
    polygon = polygon[0]

    //this.experience.collisionEngine.removeAllMarkerPoint()
    //this.experience.collisionEngine.addMarkerPoint(polygon, polygon.name)

    if(this.pathFrom && this.pathTo)
    {
      this.pathFrom = polygon
      this.pathTo = null
      return;
    }

    if (this.pathFrom) {
      this.pathTo = polygon

      this.removeAllPathsheilight()

      const path = this.getPath(this.pathFrom, polygon)
      if(path)
      {
        console.log ('-->path', path );
        this.heilightTestShortstPath(path)
      }

      return;
    }

    this.pathFrom = polygon

  }

  onSelect2Polygon = (polygon) => {
    //this.onSelectPolygon(polygon)
  }

  onDESelectPolygon = (polygon) => {
   //this.deSelectPath()
    //this.pathFrom = null
    //this.pathTo = null
  }

  selectFrom = (polygon) => {
    if(!polygon)
    {
      this.pathFrom = null
      return
    }
    this.pathFrom = polygon
  }

  selectTo = (polygon) => {
    if(!polygon)
    {
      this.pathTo = null
      return
    }
    this.pathTo = polygon
  }

  deSelectPath = () => {
    //this.pathTo = null
    //this.pathFrom = null
  }

  run = () => {
    this.experience.emit.on('onGraphLoaded', (res) => {
      this.graph = res[0]
      this.experience.emit.on('polygon-select', this.onSelectPolygon)
      this.experience.emit.on('polygon-select2', this.onSelect2Polygon)
      this.experience.emit.on('polygon-deselect', this.onDESelectPolygon)
    })
  }

  runDebug = () => {
    setTimeout(async ()=>{
      const self = this
      const polygons = this.experience.world.mapModel.polygons;
      const points = this.experience.world.mapModel.points;
      let buildNodeRes = null

      const polygonsArr = () => {
        const polys = [];
        polygons.forEach((_pol)=>{
          polys.push(_pol.name)
        })
        return polys;
      }

      const pathParams = {
        showPoint: false,
        heilightGraph: false,
        heilightNodes: false,
        buildGraph: async () => {
          this.buildNodes().then(res=>{
            console.log ('-->res', res );
            buildNodeRes = res
            if(res)
            {
              const graph = new Map();
              for (let ii = 0; ii < buildNodeRes.length; ii++) {
                const node = buildNodeRes[ii];
                graph.set(node.id, node)
                for (let i = 0; i < node.paths.length; i++) {
                  let point = node.paths[i]
                  if(pathParams.heilightNodes)
                    this.drawPoint(point.node.position, 'green', .1, 10)
                }
              }
              if(pathParams.heilightGraph)
                this.heilightGraph(graph)
            }
          })
        },
        graphToJson: () => {
          console.log ('--> graph', JSON.stringify(buildNodeRes) );
        },
        fromPolygon: 'polygon071',
        toPolygon: 'polygon230',
        testPath: () => {
          let graph = new Map();

          if(buildNodeRes)
          {
            for (let ii = 0; ii < buildNodeRes.length; ii++) {
              const node = buildNodeRes[ii];
              for (let i = 0; i < node.paths.length; i++) {
                let point = node.paths[i]
              }
              graph.set(node.id, node)
            }
          } else if(this.graph.size > 0)
          {
            this.graph.forEach((node)=>{
              graph.set(node.id, node)
            })
          }

          let start = null
          let  end = null
          console.log ('-->graph', graph );
          start = polygons.get(pathParams.fromPolygon)
          end = polygons.get(pathParams.toPolygon)
          start = graph.get(this.nodeId(start.name))
          end = graph.get(this.nodeId(end.name))
          setTimeout(()=>{
            const pathss = this.dijkstra(start, end, graph)
            self.removeAllTestedPathsHeilight()
            if(pathss)
            {
              console.log ('-->pathss', pathss );
              //console.log ('-->pathss JSON', JSON.stringify(pathss) );
              //this.heilightTestShortstPath(pathss)

              this.drawPath( pathss )
            }
          },1)
        }
      }

      const showHidePoints = (val) => {
        points.forEach((node)=>{
          node.visible = val
        })
      }

      this.debugPathFolder = this.debug.ui.addFolder('Path')
      //this.debugPathFolder.close();
      this.debugPathFolder.add( pathParams,'showPoint', 'boolean')
          .onChange( (val) => {
            showHidePoints(val)
          });

      this.debugPathFolder.add( pathParams,'heilightNodes', 'boolean')
          .name('Heilight Nodes')

      this.debugPathFolder.add( pathParams,'heilightGraph', 'boolean')
          .name('Heilight Graph')

      this.debugPathFolder.add( pathParams, 'buildGraph')
          .name('Build Graph')

      this.debugPathFolder.add( pathParams, 'graphToJson')
          .name('graph to Json')

      this.debugPathFolder.add( pathParams, 'fromPolygon', polygonsArr() ).name('From Polygon');
      this.debugPathFolder.add( pathParams, 'toPolygon', polygonsArr() ).name('To Polygon');;
      this.debugPathFolder.add( pathParams, 'testPath')
          .name('Test Path')

//=============================

      this.drawPath( testPath );



    }, 2800)
  }

}
